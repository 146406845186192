<template>
  <div class="container" v-title :data-title="$t('i18n.installOperationNode')">
    <div id="outer-title">{{ $t("i18n.installOperationNode") }}</div>
    <div v-loading="loading">
      <jl-table
        :tableData="tableData"
        @handleEdit="handleEdit"
        @init="init"
        :options="options"
        :columns="columns"
        :operates="operates"
        :total="total"
        :height="$defined.HEIGHT - 360 + 'px'"
      >
      </jl-table>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import jlTable from "../../components/table";
export default {
  name: "OpertionNode",
  components: {
    jlTable,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const { t } = useI18n();
    const router = useRouter();
    const state = reactive({
      formInline: {
        pageNumber: 1,
        pageSize: 10,
      },
      // table数据
      tableData: [],
      options: {
        // table 的参数
        isAdd: proxy.$defined.btnPermission("新增节点库"), // 是否启用新增功能
        isEdit: false, // 是否启用编辑功能
        isDelete: false, // 是否启用删除功能
        highlightCurrentRow: false, // 是否支持当前行高亮显示
        multiSelect: false, // 是否支持列表项选中功能
        pageShow: true, // 是否翻页
      },
      columns: [
        // 需要展示的列
        {
          prop: "depotName",
          label: "name",
          align: "left",
          type: "input",
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "companyName",
          label: "companyName",
          align: "left",
          row: false,
        },
      ],
      operates: {
        // 列操作按钮
        width: 140,
        fixed: "right",
        list: [
          {
            id: "1",
            label: "edit",
            icon: "el-icon-edit",
            show: proxy.$defined.btnPermission("编辑节点库"),
            plain: false,
            disabled: false,
            type: "inner",
            method: (index, row) => {
              enterPage(row.id);
            },
          },
          {
            id: "2",
            label: "delete",
            icon: "el-icon-delete",
            show: proxy.$defined.btnPermission("删除节点库"),
            plain: false,
            disabled: false,
            type: "inner",
            method: (index, row) => {
              handleDelete(row);
            },
          },
        ],
      },
      total: 0,
      loading: true,
      editData: {},
    });

    const init = async (params) => {
      state.loading = true;
      const { data } = await proxy.$api.system.installProjectDepot(params);
      state.tableData = data.records;
      state.total = data.total;
      state.loading = false;
    };

    const handleEdit = async (val) => {
      // 新增数据
      let { data } = await proxy.$api.system.addInstallProjectDepot(val);
      proxy.$defined.tip(t("i18n.new") + t("i18n.success"), "success");
      // 初始化 刷新
      enterPage(data);
    };

    const enterPage = (id) => {
      // 进入维保配置页面
      router.push({
        path: "/install/opertionNodeConfig",
        query: { depotId: id },
      });
    };

    const handleDelete = (row) => {
      proxy.$defined.confirm(
        () => {
          proxy.$api.system.delInstallProjectDepot(row.id).then(() => {
            init(state.formInline);
            proxy.$defined.tip(t("i18n.delete") + t("i18n.success"), "success");
          });
        },
        t("i18n.doYouWantToDelete"),
        t("i18n.delete")
      );
    };

    return {
      ...toRefs(state),
      handleEdit,
      init,
      handleDelete,
      enterPage,
    };
  },
};
</script>
